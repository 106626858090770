import {Modal, Card, Badge, Button, Row, Col} from 'react-bootstrap';
import {fixed2Decimals, getTokenIcon } from '../../../utils';
import {connect} from 'react-redux';
import moment from 'moment';
import { useState } from 'react';
import { fromJS } from 'immutable';

function PositionModal(props) {
    const [activeIndexes, setActiveIndexes] = useState([]);
    const renderAction = (tx, j) => {
        const decimals = props.data.get("decimals");
        switch(tx.get("event")) {
            case "Mint":
                return <li key={j}>
                    <Badge className="mb-1" bg={props.theme}><span className='text-light-2'>{tx.get("event")}</span></Badge>
                    <span className='float-end small'>{moment.unix(tx.get("timestamp")).format("ll hh:mm A")}</span> 
                    <p className='small'>{fixed2Decimals(tx.get("mintAmount"), decimals)} {props.data.get("symbol")}</p>
                </li>
            case "Borrow":
                return <li key={j}>
                    <Badge className="mb-1" bg={props.theme}><span className='text-light-2'>{tx.get("event")}</span></Badge>
                    <span className='float-end small'>{moment.unix(tx.get("timestamp")).format("ll hh:mm A")}</span> 
                    <p className='small'>Borrowed: {fixed2Decimals(tx.get("borrowed"), props.borrowAsset.get("decimals"))} {props.borrowAsset.get("symbol")}<br/>
                    Downpayment:  {fixed2Decimals(tx.get("downpayment"), props.borrowAsset.get("decimals"))} {props.borrowAsset.get("symbol")}</p>
                </li>
            case "Redeem": 
                return <li key={j}>
                    <Badge className="mb-1" bg={props.theme}><span className='text-light-2'>{tx.get("event")}</span></Badge>
                    <span className='float-end small'>{moment.unix(tx.get("timestamp")).format("ll hh:mm A")}</span> 
                    <p className='small'>{fixed2Decimals(tx.get("redeemed"), decimals)} {props.data.get("symbol")}</p>
                </li>
            case "SwapAndSettle":
                return <li key={j}>
                     <Badge className="mb-1" bg={props.theme}><span className='text-light-2'>{tx.get("event")}</span></Badge>
                     <span className='float-end small'>{moment.unix(tx.get("timestamp")).format("ll hh:mm A")}</span> 
                     <p className='small'>{fixed2Decimals(tx.get("redeemed"), decimals)} {props.data.get("symbol")}</p>
                </li>
            case "RepayBorrow":
                return <li key={j}>
                     <Badge className="mb-1" bg={props.theme}><span className='text-light-2'>{tx.get("event")}</span></Badge>
                     <span className='float-end small'>{moment.unix(tx.get("timestamp")).format("ll hh:mm A")}</span> 
                     <p className='small'>{fixed2Decimals(tx.get("repaid"), props.borrowAsset.get("decimals"))}<br/>
                     Total Repaid: {fixed2Decimals(tx.get("repaidTotal"), props.borrowAsset.get("decimals"))} {props.borrowAsset.get("symbol")}</p>
                </li>
            default:
                return <li key={j}></li>
        }
    }

    const tokenImage = <img  src={getTokenIcon(props.data.get("symbol"))} className="me-1" height="16" alt="token"/>

    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.theme === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="position-relative text-center border-0">
                <Modal.Title className='fs-6 text-light-1'>
                    {tokenImage}{props.data.get("symbol")} - Closed Positions
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {props.data.get("closed").map((item, i) => {
                    const timeline = item.get("timeline");
                    return <Card key={i} className='mb-4 text-light-1 p-0' syle={{position: "relative"}}>
                        <Card.Header>
                            <Row className='align-items-center py-2'>
                                <Col>
                                    {tokenImage}
                                    {fixed2Decimals(item.get("redeemed"), props.data.get("decimals"))} 
                                </Col>
                                <Col>
                                    <div className='small text-light-2'><i className='fa fa-calendar me-2'/>Opened</div>
                                    <div className='small'>{moment.unix(timeline.get(0).get("timestamp")).format("ll")}</div>
                                </Col>
                                <Col>
                                    <div className='small text-light-2'><i className='fa fa-calendar me-2'/>Closed</div>
                                    <div className='small'>{moment.unix(timeline.get(timeline.size - 1).get("timestamp")).format("ll")}</div>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body className='p-0 pe-2 small'>{activeIndexes.includes(i) && 
                            <ul className='timeline text-light-1'>
                                {timeline.map((tx, j)=> {
                                    return renderAction(tx, j);
                                })}
                            </ul>
                        }</Card.Body>
                        <Button
                            onClick={()=> {
                                let indexes = fromJS(activeIndexes).toJS();
                                if (indexes.includes(i)) {
                                    indexes = indexes.filter((a)=> a !== i);
                                } else {
                                    indexes.push(i);
                                }
                                setActiveIndexes(indexes);
                            }}
                            size='sm'
                            className='text-light-1'
                            variant={props.theme}
                            style={{
                                position: "absolute",
                                bottom: "-10px",
                                right: "50%",
                                zIndex: 99
                            }}
                        >
                            <i className={`fas fa-angle-${activeIndexes.includes(i) ? "up": "down"}`}/>
                        </Button>
                    </Card>
               })}
            </Modal.Body>
        </Modal>
    ) 
}



const mapStateToProps = (state, props) => {
   return {
        theme: state.get("themeMode"),
   }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionModal);