import {connect} from 'react-redux';

import {Card, Row, Col, Spinner} from 'react-bootstrap';
import {decimal2Fixed, fixed2Decimals, getTokenIcon, toPrecisin} from '../../../utils';
import Snapshot from '../../portfolio/components/Snapshot';

function Balance(props) {
    const getBalance = balanceType => {
        const balanceToken = props.assets.find((item)=> item.get("lendEnabled"));
        if (props.accountSnapshot.hasIn([balanceToken.get("address"), balanceType])) {
            let balance = props.accountSnapshot.getIn([balanceToken.get("address"), balanceType])
            return <div className="text-balances fs-6" >
                {toPrecisin(fixed2Decimals(balance, balanceToken.get("decimals")), 2)}
                <span className="ms-2 align-baseline"><img height="15" src={getTokenIcon(balanceToken.get("symbol"))} alt="token"/></span>
                <span className="ms-1 text-light-2 align-middle">{balanceToken.get("symbol")}</span>
            </div>
        } else {
            return <div className="text-muted">---</div>;
        }
    }

    return (
        <Card body className="mt-2">
            <Row>
                <Col>
                    <div className="text-light-2 fs-6">Supply Balance</div>
                    {getBalance("lendBalance")}
                </Col>
                {props.isLoading === true && <Col className="text-center">
                    <Spinner variant='secondary' animation='grow' size="lg"/>
                </Col>}
                <Col className="text-end">
                    <div className="text-light-2 fs-6 small">Borrow Balance</div>
                    {getBalance("borrowBalance")}
                </Col>
            </Row>

            {/* <div className="fs-5">Lend Tokens</div>
            <div className="fs-6 text-muted">Tokens you can lend to earn interest</div>
            <div className="token-list">
                {props.assets.map((token, j)=> <Row key={j} className="token-list-item align-items-center">
                    <Col>
                        <div className="title">Asset</div>
                        <div className="value"><img src={getTokenIcon(token.get("symbol"))} alt="token"/>{token.get("symbol")}</div>
                    </Col>
                    <Col>
                        <div className="title">APY</div>
                        <div className="value">{getAPY(token.get("address"))}</div>
                    </Col>
                    <Col>
                        <div className="title">Pool Size</div>
                        <div className="value">-*-</div>
                    </Col>
                    <Col>
                        <div className="title">Utilization</div>
                        <div className="value">-*-</div>
                    </Col>
                    <Col className='d-grid justify-content-end'>
                        <Button variant="primary" className="action" onClick={()=> setActiveLendToken(token)}>Lend</Button>
                    </Col>
                </Row>)}
            </div>
            {activeLendToken !==null && <LendModal
                show={activeLendToken !== null}
                token={activeLendToken}
                lendAPY={getAPY(activeLendToken.get("address"))}
                onHide={()=> setActiveLendToken(null)}
            />} */}
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot")
    }
}

export default connect(mapStateToProps, {})(Balance);