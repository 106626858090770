import {Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';

import LendAssets  from '../dashboard/components/LendAssetsV2';
import WalletBalances from '../WalletBalances';
import Balance from '../dashboard/components/Balance';

function Lend(props) {
    return <Container>
        <WalletBalances />
        <Balance />
        {/* <Row>
            <Col md={6}>
               <LendAssets  />
            </Col>
            <Col md={6}>
                <BorrowAssets assets = {props.assets.filter((item)=> item.get("borrowEnabled"))} />
            </Col>
        </Row> */}
        <LendAssets/>
        </Container>
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accAdderss: state.get("accAddress"),
        accountSnapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
    }
}

const mapDispatchtoProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(Lend)