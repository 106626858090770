import { Alert, Col, Row } from "react-bootstrap";

export default function ConnectHelp(props) {
    return (
        <div>
            {props.connected === false && <div 
                onClick={()=> props.onConnect()}
                style={{width: "300px", margin: "auto"}}
                className={`text-center cursor-pointer ${props.themeMode === "dark" ? "bg-dark": "light-connect"} px-2 py-4 rounded-3 mt-5`}
            >
                <div className="text-light-1 mt-1 fs-6">
                    <img height="25" className="me-2"  alt="xdcpay" src={"https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png"}/>
                    <img height="25" className="me-2"  alt="metamask" src={props.providers.METAMASK.logo}/>
                    Connect your wallet
                </div>
            </div>}
            <Row className="mt-5 text-light-1">
                <Col md={{span: 6, offset: 3}} className=" rounded-3 p-4">
                    <div className="h5 fw-thin text-center">
                        Connect with XDCPay or Metamask
                    </div>
                    <hr/>
                    <ul>
                        <li>
                            Connect with XDCPay
                            <ul>
                                <li>Install <a href="https://chrome.google.com/webstore/detail/xdcpay/bocpokimicclpaiekenaeelehdjllofo" target="blank">Chrome Extension <i className="fas fa-external-link"></i></a></li>
                                <li>Setup wallet and select XDC Apothem Testnet network</li>
                                <li><a href="https://docs.xdc.org/xdc-tools/xdc-pay" target="_blank">More Details <i className="fas fa-external-link"></i></a></li>
                            </ul>
                        </li>
                        <li>
                            Connect with Metamask
                            <ul>
                                <li>Install  <a href="https://metamask.io/" target="_blank" rel="noreferrer">Metamask <i className="fas fa-external-link"></i></a></li>
                                <li><a href="https://docs.xdc.community/get-details/wallet-integration/metamask" target="_blank">Add Apothem Testnet Network to Metamask <i className="fas fa-external-link"></i></a></li>
                            </ul>
                        </li>
                    </ul>
                </Col>
            </Row>
        </div>
    )
}