import {Container, Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';

import WalletBalances from '../WalletBalances';
import BorrowAssets from '../dashboard/components/BorrowAssetsV2';
import Balance from '../dashboard/components/Balance';

function Borrow(props) {
    return <Container>
        <WalletBalances />
        <Balance />
        <BorrowAssets/>
        </Container>
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accAdderss: state.get("accAddress"),
        accountSnapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
    }
}

const mapDispatchtoProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchtoProps)(Borrow)