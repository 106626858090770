
import { Card, Col, Row } from 'react-bootstrap';
import {connect} from 'react-redux';
import { getUSDPrice } from '../../../utils';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

function Snapshot(props) {

    let borrowAssets = props.assets.filter((item)=> item.get("borrowEnabled"));
    const getTotalValue = () => {
        let value = 0;
        props.assets.forEach((token)=> {
            value = value + Number(getUSDPrice(
                props.accountSnapshot.getIn([token.get("address"), "oraclePrice"]),
                props.accountSnapshot.getIn([token.get("address"), "lendBalance"]),
                token.get("decimals"))
            );
        });
        return value.toFixed(2);
    }

    const getTotalBorrowValue = () => {
        let value = 0;
        borrowAssets.forEach((token)=> {
            value = value + Number(getUSDPrice(
                props.accountSnapshot.getIn([token.get("address"), "oraclePrice"]),
                props.accountSnapshot.getIn([token.get("address"), "borrowBalance"]),
                token.get("decimals"))
            );
        });
        return value.toFixed(2);
    }
   
    const totalValue = getTotalValue();
    const totalBorrowValue = getTotalBorrowValue();

    const liquidationPoint = (Number(totalValue) - Number(props.accountLiquidity)).toFixed(2);

    const labels = {
        [totalValue]: '$'+totalValue,
        [liquidationPoint]: 'Liquidation Point: $'+liquidationPoint,
    }

    return <Card body className=''>
        <Row>
            <Col>
                <div className="fs-6 text-light-1">Current Value</div>
                <div className="text-success fs-4 fw-bold">$ {totalValue}</div>
               
            </Col>
            {Number(totalBorrowValue) > 0 && <Col md={6}>
                <Slider
                    min={0}
                    max={Number(totalValue)}
                    value={Number(liquidationPoint)}
                    // orientation='vertical'
                    labels={labels}
                    // handleLabel={vertical}
                    //   format={(a)=> }
                    disabled={true}
                    tooltip={false}

                    // onChange={this.handleChangeVertical}
                />
            </Col>}
            <Col className="text-end">
                <div className="fs-6 text-light-1">Borrowed</div>
                <div className="text-danger fs-4 fw-bold">$ {totalBorrowValue}</div>
            </Col>
        </Row>
    </Card>
            
}   

const mapStateToProps = state => {
    return {
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        compAddress: state.getIn(["contracts", "comptroller"]),
        accountLiquidity: state.getIn(["portfolio", "accountLiquidityDecimals"])
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Snapshot);