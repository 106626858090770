import {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Spinner, OverlayTrigger, Popover, Table} from 'react-bootstrap';
import { decimal2Fixed, fixed2Decimals, getContract, getTokenIcon, divFixed, getUSDPrice } from '../../../../utils';
import { redeemUnderlying } from '../../../../redux/actions';
import { comptrollerABI, pTokenABI } from '../../../../utils/abi';

function Redeem(props) {
    const [sliderBalance, setSliderBalance] = useState(0);
    const [hypoResponse, setHypoResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=> {
        if(Number(sliderBalance) > 0  && Number(sliderBalance) <= walletBalance) {
            checkShortfall();
        }
    }, [sliderBalance])

    const token = props.token;
    let walletBalance = 0;
    let borrowBalance = 0;
    let borrowAsset = props.assets.find((item)=> item.get("borrowEnabled"));
    if ( props.snapshot.hasIn([token.get("address"), "lendBalance"]) ) {
        let balance = Number(props.snapshot.getIn([token.get("address"), "lendBalance"]));
        walletBalance = fixed2Decimals(balance, token.get("decimals"));
    }

    if ( props.snapshot.hasIn([borrowAsset.get("address"), "borrowBalance"]) ) {
        let balance = props.snapshot.getIn([borrowAsset.get("address"), "borrowBalance"]);
        borrowBalance = fixed2Decimals(balance, borrowAsset.get("decimals"), 2, 1);
    }

    const redeem = () => {
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        props.redeemUnderlying(props.web3, contract, props.accAddress, decimal2Fixed(sliderBalance, token.get("decimals")));
        props.onHide();
    }

    const getTotalValue = (hypo=false) => {
        let value = 0;
        props.assets.forEach((t)=> {
            let amount =  props.accountSnapshot.getIn([t.get("address"), "lendBalance"]);
            if (hypo && token.get("address") === t.get("address")) {
                amount = Number(amount) - Number(decimal2Fixed(sliderBalance, t.get("decimals")));
            }
            value = value + Number(getUSDPrice(
                props.accountSnapshot.getIn([t.get("address"), "oraclePrice"]),
                amount,
                t.get("decimals"))
            );
        });
        return value.toFixed(2);
    }

    const checkShortfall = async () => {
        setIsLoading(true);
        const contract = getContract(props.web3, comptrollerABI, props.comptroller);
        const balance = decimal2Fixed(sliderBalance, token.get("decimals"));
        const tokens = divFixed(balance, fixed2Decimals(props.accountSnapshot.getIn([token.get("address"), "exchangeRateStored"]), 18));

        await contract.methods.getHypotheticalAccountLiquidity(
            props.accAddress,
            token.get("address"),
            tokens,
            0
        ).call((error, result)=> {
            const res = {
                liq:  result["1"],
                shortfall:  result["2"]
            }
            setHypoResponse(res);
            setIsLoading(false);

            // if(shtfall === 0) {
            //     redeem();
            // } else {
            //     setShortfall(shtfall);
            //     setIsLoading(false);
            // }
        });
    }

    const getLiquidity = ()=>{
        if (Number(hypoResponse.shortfall) > 0) {
            return <span className="text-danger">- $ {fixed2Decimals(hypoResponse.shortfall, 18, 2)}</span>
        } else {
            return <span>$ {fixed2Decimals(hypoResponse.liq, 18, 2)}</span>
        }
    }

    const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Account Liquidity</Popover.Header>
          <Popover.Body>
            ≈ <em>total protfolio value - total borrow balance.</em>
            <div className='small text-light-2 mt-2'><i className='fas fa-info-circle'/> Account Liquidity close to zero value triggers account liquidation.</div>
          </Popover.Body>
        </Popover>
      );
      

    return(
        <div>
            {/* <div className="mt-4 mb-2"><small className='text-light-1'>Withdraw {token.get("symbol")}</small></div> */}
            <Row className="mb-4 align-items-center">
                <Col xs={7} className="border-end border-2">
                    <div className=""><small className='text-light-1'>Withdraw {token.get("symbol")}</small></div>
                    <div className="input-group mt-1 ">
                        <input
                            value={sliderBalance}
                            className="form-control"
                            onChange={(e)=> setSliderBalance(Number(e.target.value))}
                            type="number"
                        />
                        <button onClick={()=> setSliderBalance(walletBalance)} className="btn btn-primary">Max</button>
                    </div>
                </Col>
                <Col>
                    {/* <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div> */}
                    <div className="fw-bold mt-1">
                        <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        {walletBalance} 
                    </div>   
                </Col>
            </Row>
            {isLoading && <div className='text-center'>
                <Spinner variant="secondary" animation="grow" />
            </div>}
           
            {/* <div className='d-grid mt-4'> 
                <Button
                    size="lg"
                    disabled={Number(sliderBalance) <=0 || Number(sliderBalance) > walletBalance || isLoading}
                    onClick={()=> checkShortfall()}
                >Withdraw {isLoading && <Spinner className="ms-2"  size="sm" variant="light" animation='grow'/>}</Button>
            </div> */}
            { !isLoading &&  hypoResponse !== null && Number(sliderBalance) > 0 && Number(sliderBalance) <= walletBalance && <div>
                <div className="mb-4 h6 fw-bold text-light-2">
                    Withdraw {sliderBalance} {token.get("symbol")} (≈ $ {getUSDPrice(
                        props.accountSnapshot.getIn([token.get("address"), "oraclePrice"]),
                        decimal2Fixed(sliderBalance, token.get("decimals")),
                        token.get("decimals"))
                    })
                </div>
                <Table className="text-light-1 small rounded-5" striped="columns" bordered  variant={props.thememMode}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Portfolio Now</th>
                            <th>
                                Portfolio Estimation after withdrawl
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Borrow Balance
                            </td>
                            <td>$ {borrowBalance}</td>
                            <td>$ {borrowBalance}</td>
                        </tr>
                        <tr>
                            <td>Portfolio Value</td>
                            <td>$ {getTotalValue(false)}</td>
                            <td>$ {getTotalValue(true)}</td>
                        </tr>
                        <tr>
                            <td>
                                Account Liquidity
                                <OverlayTrigger  trigger="click" placement="bottom" overlay={popover}>
                                    <i className="fas fa-circle-question cursor-pointer"/>
                                </OverlayTrigger>
                            </td>
                            <td>$ {props.accountLiquidity}</td>
                            <td>
                                {getLiquidity()}
                                
                            </td>
                        </tr>
                    </tbody>
                </Table>
                {Number(hypoResponse.shortfall) > 0  ?  <div className='text-light-1 mt-2 small'>
                    <i className='fas fa-warning me-2'/>
                    {/* Withdrawl of {sliderBalance} {token.get("symbol")} will change your portfolio value to $. */}
                    You can not have negative Account Liquidity. Your borrow balance can not be greater than your portfolio value. Try repaying your borrow balance first.
                </div> : Number(borrowBalance) > 0 ? <div className='text-light-1 mt-2 small'>
                    <i className='fas fa-warning me-2'/>
                    {/* Withdrawl of {sliderBalance} {token.get("symbol")} will change your portfolio value to $. */}
                    Widthdrawl of {sliderBalance} {token.get("symbol")} will make your portfolio close to the liquidation point. 
                </div>: null}
                {Number(hypoResponse.shortfall) <=0 && <div className="row mt-4">
                    <Col className="d-grid">
                        <Button size='lg' onClick={()=> redeem()}>Withdraw</Button>
                   </Col>
                   <Col className="d-grid">
                        <Button variant='secondary' size="lg" onClick={()=> {
                            setSliderBalance(0);
                            setHypoResponse(null)
                        }}>Cancel</Button>
                    </Col>
                </div>}
            </div>}

        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        assets: state.get("assets"),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        comptroller: state.getIn(["contracts", "comptroller"]),
        accountSnapshot: state.get("accountSnapshot"),
        thememMode: state.get("themeMode"),
        accountLiquidity: state.getIn(["portfolio", "accountLiquidityDecimals"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redeemUnderlying: (web3, contract, accAddress, amount) => dispatch(redeemUnderlying(web3, contract, accAddress, amount))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redeem);