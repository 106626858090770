import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import { approveToken } from '../../../../redux/actions';
import { getContract } from '../../../../utils';
import { pTokenABI } from '../../../../utils/abi';
import BalanceHelp from './BalanceHelp';

function Approve(props) {

    const approve = () => {
        const contract = getContract(props.web3, pTokenABI, props.token.get("uAddress"));
        props.approveToken(props.web3, contract, props.accAddress, props.token.get("address"));
    }
    
    return(
        <div>
            <BalanceHelp token={props.token}/>
            <div className="fw-lighter text-light-2 fs-6 text-center py-4 mb-4">
                To Supply, Borrow &amp; Buy and Repay {props.token.get("symbol")} to the protocol, you need to enable it first.
            </div> 
            <div className="d-grid">
                <Button className="btn-lg" onClick={()=> approve()}>Approve</Button>
            </div>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    return {
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        approveToken: (contract, web3, accAddress, tokenAddress) => dispatch(approveToken(contract, web3, accAddress, tokenAddress)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Approve);