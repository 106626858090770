import {useState} from 'react';
import {connect} from 'react-redux';
import {Row, Col, Button, Alert} from 'react-bootstrap';
import { decimal2Fixed, fixed2Decimals, getContract, getTokenIcon, toPrecisin } from '../../../../utils';
import { repayBorrow } from '../../../../redux/actions';
import { pTokenABI } from '../../../../utils/abi';

function Repay(props) {
    const [sliderBalance, setSliderBalance] = useState(0);
    const token = props.token;
    let repayBalance = 0;
    if ( props.snapshot.hasIn([token.get("address"), "borrowBalance"]) ) {
        repayBalance = Number( fixed2Decimals(props.snapshot.getIn([token.get("address"), "borrowBalance"]), token.get("decimals")) );
    }

    let walletBalance = 0;
    if ( props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
        walletBalance = Number( fixed2Decimals(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]), token.get("decimals")) );
    }


    const repay = ()=> {
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        props.repayBorrow(
            props.web3,
            contract,
            props.accAddress,
            decimal2Fixed(sliderBalance, token.get("decimals"))
        );
        props.onHide();
    }

    return(
        <div>
            <small className='text-light-1'>Repay Borrow</small>
            <Row className='mt-1'>
                <Col>
                    <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                    <span className='text-light-2 fw-bold'>Borrow Balance Total</span>
                </Col>
                <Col className="text-end text-dark fw-bold">
                    {repayBalance}
                </Col>
            </Row>

            <Row className="mt-4 mb-4 align-items-center">
                <Col xs={7} className="border-end border-2">
                    <div className=""><small className='text-light-1'>Repay {token.get("symbol")}</small></div>
                    <div className="input-group mt-1 ">
                        <input
                            value={sliderBalance}
                            className="form-control"
                            onChange={(e)=> setSliderBalance(e.target.value)}
                            type="number"
                        />
                        <button onClick={()=> setSliderBalance(repayBalance)}className="btn btn-primary">Max</button>
                    </div>
                </Col>
                <Col>
                    <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div>
                    <div className="fw-bold mt-1">
                        <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        {walletBalance} 
                    </div>   
                </Col>
            </Row>
            {Number(sliderBalance) > repayBalance && <Alert variant="secondary" className="mb-3 small fw-bold">
                <small>Repay amount should not exceed borrow balance total. </small>
            </Alert>}
            {Number(sliderBalance) > walletBalance && <Alert variant="secondary" className="mb-3 small fw-bold">
                <small>
                    Insufficient wallet balance
                    <span className="ms-3 me-3 text-muted">|</span>
                    <i className="fa fa-wallet"/> {walletBalance} {token.get("symbol")}
                </small>
            </Alert>}
            <div className='d-grid'>
                <Button
                    size="lg"
                    disabled={(sliderBalance <= 0 || sliderBalance > repayBalance || sliderBalance > walletBalance)}
                    onClick={()=> repay()}
                >
                    Repay
                </Button>
            </div>
        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        repayBorrow: (web3, contract, accAddress, amount) => dispatch(repayBorrow(web3, contract, accAddress, amount)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Repay);