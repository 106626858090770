
export const transaction = {
    isActive: false,
    isLoading: false,
    isCompleted: false
};

export const mainnetAccount =  {
    isFetched: false,
    status: 0,
    message: "",
    isUpdating: false,
    updateMessage: null
}

const mumbaiTestnetAssets = [
    {
        "name": "X USDT",
        "symbol": "xUSDT",
        "address": "0x8c850634c8B416C3450ec8d39Eede189FC0a89dA",
        "uAddress": "0x551447a0dfcb8233ade8ecb748d4cd822b081e5d",
        "decimals": 6,
        "lendEnabled": true,
        "borrowEnabled": true,
        "buyEnabled": false,
        "buyAssets": ["0xc958BD111171C7c78013802587587721C8138642"]
    },
    {
        "name": "WXDC",
        "symbol": "WXDC",
        "address": "0xc958BD111171C7c78013802587587721C8138642",
        "uAddress": "0x1c2120e25292a38229717ef56307b686f62d52fc",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x8c850634c8B416C3450ec8d39Eede189FC0a89dA"]
    }
];

const defaultState =  {
    themeMode: "dark",
    network: "apothem",
    connected: false,
    networkId: null,
    chainId: null,
    accAddress: "",
    web3: null,
    isLoadingNativeBalance: false,
    nativeBalance: -1,
    dexes: [
        // {"name": "UniswapV3", "id": "1", "quoterAddress": "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6"},
        {"name": "XSwap", "id": "2", "quoterAddress": "0x3F11A24EB45d3c3737365b97A996949dA6c2EdDf"}
    ],
    contracts: {
        libABI: "0xfffabb83f923e0f0f706de79b7e748c3c68b2753",
        uniswapQuoter: "0x3F11A24EB45d3c3737365b97A996949dA6c2EdDf",
        comptroller: "0xF3bF41cCADdd3117A3d93293cc6F2f111DbF9E35",
        xdcFaucetAddress: "0x2cE073A6731E33D58079f0e60f596B585B70Ec31",
    },
    supportedChains: [
        {
            name: "XDC Apothem Network",
            shortName: "mumbai",
            chain: "apothem",
            network: "",
            chainId: 51,
            networkId: 51,
            nativeCurrency: {
                symbol: "XDC",
                name: "XDC",
                decimals: "18",
                contractAddress: "0x2a5c77b016Df1b3b0AE4E79a68F8adF64Ee741ba",
                balance: ""
            },
            webURL: "https://explorer.apothem.network"
        },
    ],
    assets: mumbaiTestnetAssets,
    accountSnapshot: {
        isLoading: false
    },
    balanceAndAllowances: {
        isLoading: false,
        uTokens: {}
    },
    swapEstimates: {
    },
    totalUnderlyingCash: {

    },
    transaction,
    mainnetAccount,
    transactions: {
        isLoading: false,
        data: []
    },
    portfolio: {
        accountLiquidity: 0,
        accountLiquidityDecimals: 0,
        compAccrued: 0
    },
    historicData: {},
    nftCollection: {
        isLoading: false,
        data: []
    },
    mintedCollection : {
        isLoading: false,
        data: []
    }

}


export default defaultState;