import BigNumber from 'bignumber.js';
import usdcIcon from '../../images/tokens/usdc.svg';
import wethIcon from '../../images/tokens/weth.svg';
import wbtcIcon from '../../images/tokens/wbtc.svg';
import wmaticIcon from '../../images/tokens/wmatic.svg';
import aaveIcon from '../../images/tokens/aave.svg';
import usdtIcon from '../../images/tokens/usdt.svg';
import xusdtIcon from '../../images/tokens/xusdt.png';
import wxdcIcon from '../../images/tokens/wxdc.png';

export function shortAddress(addr) {
    if (addr) {
        return addr.substring(0, 6) + "..." + addr.substring(addr.length - 4);
    } else {
        return "";
    }
}

export function getChainData(supportedChains, chainId) {
    const chainData = supportedChains.filter(
      (chain) => chain.chain_id === chainId
    )[0];
  
    if (!chainData) {
      throw new Error("ChainId missing or not supported");
    }
    return chainData;
}

export function getTokenIcon(symbol) {
    symbol = symbol.toLowerCase();
    switch(symbol) {
        case "usdc":
            return usdcIcon;
        case "weth":
            return wethIcon;
        case "wbtc":
            return wbtcIcon;
        case "wmatic":
            return wmaticIcon;
        case "aave":
            return aaveIcon;
        case "usdt":
            return usdtIcon;
        case "usdc-2":
            return usdcIcon;
        case "weth-2":
            return wethIcon;
        case "xusdt":
            return xusdtIcon;
        case "wxdc":
            return wxdcIcon;
        default:
            return "na"
    }
}

export function getContract(web3, abi, address) {
    return new web3.eth.Contract(abi, address);
}


//calculations

export function toPrecisin(value, dec) {
    if (value.toString().indexOf(".") > -1) {
      var valueA = value.toString().split(".");
  
      value = valueA[0] + "." + valueA[1].substring(0, dec);
    }
    return value;
}
  
export function isEmpty(value) {
    return value == null || value.length === 0;
}

export function decimal2Fixed(amount, decimals) {
    var tDec = new BigNumber("10")
        .exponentiatedBy(new BigNumber(decimals))
        .toFixed();
    return new BigNumber(amount).multipliedBy(tDec).toFixed();
}

export function fixed2Decimals(amount, decimals, precision=null, round = 0) {
    var tDec = new BigNumber("10")
        .exponentiatedBy(new BigNumber(decimals))
        .toFixed();
    let value = new BigNumber(amount).dividedBy(tDec);
    return precision ===null ? value.toFixed() : value.toFixed(precision, round)
}

export function mul(amount1, amount2) {
    return new BigNumber(amount1).multipliedBy(new BigNumber(amount2)).toFixed();
}

export function division(amount1, amount2) {
    return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed();
}

export function mulFixed(amount1, amount2) {
    return new BigNumber(amount1).multipliedBy(new BigNumber(amount2)).toFixed(0, 2);
}

export function divFixed(amount1, amount2) {
    return new BigNumber(amount1).dividedBy(new BigNumber(amount2)).toFixed(0);
}

export function minus(amount1, amount2) {
    return new BigNumber(amount1).minus(new BigNumber(amount2)).toFixed();
}

export function minusFixed(amount1, amount2) {
    return new BigNumber(amount1).minus(new BigNumber(amount2)).toFixed(0);
}

export function addFixed(amount1, amount2) {
    return new BigNumber(amount1).plus(new BigNumber(amount2)).toFixed(0);
}

export function getUSDPrice(oraclePrice, amount, tokenDecimals) {
    oraclePrice = fixed2Decimals(oraclePrice, 8);
    amount = fixed2Decimals(amount, tokenDecimals);
    return  new BigNumber(oraclePrice).multipliedBy(new BigNumber(amount)).toFixed(2, 1);
}

export function calcAPY(rate, days) {
    // APY = ((((Rate / ETH Mantissa * Blocks Per Day + 1) ^ Days Per Year)) - 1) * 100
    const numBlocksPerDay = 5760;
    return (Math.pow(
        (Number(rate) / decimal2Fixed(1, 18)) * numBlocksPerDay + 1,
        days
        ) -
        1) *
        100;
}

export function numFormatter(num) {
    if(num ===0) {
        return 0;
    } else if(num > 999 && num < 1000000){
        return (num/1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
    }else if(num > 1000000){
        return (num/1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
    }else if(num < 900){
        return num; // if value < 1000, nothing to do
    }
}

export function decodeDexData(web3, dexId, fee) {
    return web3.eth.abi.encodeParameter({
        "DexData": {
            "dexId": "uint8",
            "fee": "uint24",
        }
    }, {
        "dexId": dexId,
        "fee": fee,
    });
}

export function areEqual(amount1, amount2) {
    return new BigNumber(amount1).isEqualTo(new BigNumber(amount2));
}