import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Alert, Dropdown, DropdownButton, Badge } from 'react-bootstrap';
import { fixed2Decimals, getTokenIcon, toPrecisin, decimal2Fixed, getContract, mulFixed, decodeDexData, minusFixed, divFixed } from '../../../../utils';
import { borrowAndBuy, fetchEstimate, fetchTotalCash } from '../../../../redux/actions';
import { libABI, pTokenABI, quoterABI } from '../../../../utils/abi';
import { fromJS } from 'immutable';
import { Spinner } from 'react-bootstrap';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import BalanceHelp from './BalanceHelp';
import DexQuotes from '../DexQuotes';
import Slider from 'react-rangeslider';

function Borrow(props) {
    const [selectedBuyFactor, setSelectedBuyFactor] = useState(1.1);
    const [targetValue, setTargetValue] = useState("");
    const [estimate, setEstimate] = useState(null);

    useEffect(()=> {
        fetchCash(props.borrowToken);
    }, [])

    const fetchCash = (borrowToken)=> {
        props.fetchTotalCash(getContract(props.web3, pTokenABI, borrowToken.get("address")), borrowToken.get("address"))
    }
   
    const borrowToken = props.borrowToken;
    const targetToken  = props.targetToken;
    const buyFactor = props.snapshot.getIn([borrowToken.get("address"), "buyFactor"]);
    // const buyFactor =5;

    
    let walletBalance = 0;
    let walletBalanceDisplay = "0";
    if ( props.uTokenMeta.hasIn([borrowToken.get("uAddress"), "balance"]) ) {
        walletBalance = Number(props.uTokenMeta.getIn([borrowToken.get("uAddress"), "balance"]));
        walletBalanceDisplay = toPrecisin( fixed2Decimals(walletBalance, borrowToken.get("decimals")), 4);
    }

    const onTargetValueChange = value => {
        setTargetValue(value);
        // if (value !== "" && value > 0) {
        //     getEstimate(value);
        // }
    }

    const isValidEstimate = estimate !==null && Number(estimate.quote) > 0;

    // const isValidEstimate = props.estimates.has(targetValue) &&
    //     props.estimates.getIn([targetValue, "isLoading"]) === false &&
    //     props.estimates.getIn([targetValue, "error"]) === null

    // const getEstimate = value => {
    //     let data = {
    //         borrowToken: borrowToken.get("uAddress"),
    //         targetToken: targetToken.get("uAddress"),
    //         value: value, 
    //         decimalValue: decimal2Fixed(value, targetToken.get("decimals")),
    //         uniswapQuoter: props.uniswapQuoter
    //     }
    //     const contract = getContract(props.web3, libABI, props.libAddress);
    //     props.fetchEstimate(contract, data);
    // }

    const renderEstimate = () => {
        if(props.estimates.has(targetValue)) {
            if(props.estimates.getIn([targetValue, "isLoading"])) {
                return <Spinner animation="grow" variant="secondary" />
            } else if (props.estimates.getIn([targetValue, "error"]) !== null) {
                return <small className="text-light-2">Not enough Uniswap Liquidity</small>;
            } else {
                return <div className="text-dark fw-bold text-end">
                    {fixed2Decimals(props.estimates.getIn([targetValue, "estimate"]), borrowToken.get("decimals"))}
                    {/* <img className="ms-2" height={16} src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/> */}
                    &nbsp;{borrowToken.get("symbol")}
                </div>;
            }
        } else {
            return null;
        }
    }

    let partialAmount = 0;
    let borrowAmount = 0;
    // let maxBorrow = 0;

    // if(isValidEstimate) {
    //     partialAmount = mulFixed(estimate.quote, 1 / (Number(selectedBuyFactor)+1))
    //     borrowAmount =  minusFixed(estimate.quote , partialAmount);
    // }

    if(isValidEstimate) {
        partialAmount = divFixed(estimate.quote, selectedBuyFactor);
        borrowAmount =  minusFixed(estimate.quote , partialAmount);
    }
    // maxBorrow = mulFixed(partialAmount, buyFactor);

    
    const borrowAndBuy = () => {
        const contract = getContract(props.web3, pTokenABI, borrowToken.get("address"));
        props.borrowAndBuy(
            props.web3,
            contract,
            props.accAddress,
            {
                borrowAmount: borrowAmount,
                partialAmount: partialAmount,
                targetAmount: decimal2Fixed(targetValue, targetToken.get("decimals")),
                targetAddress: targetToken.get("address"),
                dexData: decodeDexData(props.web3, estimate.dexId, decimal2Fixed(3, 3))
            }
        );
        props.onHide();

    }

    const renderEstimateBreakup = ()=> {
        return <Row className="mt-4 slider-balance">
            <Col className="text-center border-end border-2">
                <div className="mb-2"><small className='text-light-1'>PAY</small></div>
                <div className="breakup-value">{partialAmount === 0 ?  "---" : fixed2Decimals(partialAmount, borrowToken.get("decimals"), 6)}</div>
                <div className="text-light-1 fw-lighter">
                    <small>
                        <img className="ms-1 me-1" height={12} src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                        {borrowToken.get("symbol")}
                    </small>
                </div>
            </Col>
            <Col className="text-center">
                <div className="mb-2"><small className='text-light-1'>BORROW</small></div>
                <div className='breakup-value'>{borrowAmount === 0 ? "---"  : fixed2Decimals(borrowAmount, borrowToken.get("decimals"), 6)}</div>
                <div className="text-light-1 fw-lighter">
                    <small>
                        <img className="ms-1 me-1" height={12} src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                        {borrowToken.get("symbol")}
                    </small>
                </div>
            </Col>
        </Row>
    }

    return(
        <div>
            <BalanceHelp token={borrowToken}/>
            <Row className='align-items-center'>
                <Col>
                    <DropdownButton
                        menuVariant={props.themeMode}
                        variant="primary"
                        title={borrowToken.has("symbol") ? <span>
                            Borrow {borrowToken.get("symbol")} <img height="20" src={getTokenIcon(borrowToken.get("symbol"))} alt="token"/>
                        </span> : ""}
                    >
                        {props.borrowAssets.map((token, i)=> {
                            return (
                                <Dropdown.Item onClick={()=> {
                                    setSelectedBuyFactor(1);
                                    setTargetValue("");
                                    props.onBorrowTokenChange(token);
                                    fetchCash(token)
                                }} key={i} active={borrowToken.get("address") === token.get("address")}>
                                    {token.get("symbol")}<img src= {getTokenIcon(token.get("symbol"))} alt="token" height="20" className='ms-2'/>
                                </Dropdown.Item>
                            )
                        })}
                    </DropdownButton>
                </Col>
                <Col className='text-end text-dark fw-bold'>
                    {/* <img height="20" className='me-2' src={getTokenIcon(borrowToken.get("symbol"))} alt={borrowToken.get("symbol")}/> */}
                    <span className='text-light-2 fw-bold me-2'>APY</span>
                    {props.borrowAPY}
                </Col>
            </Row>
            <Row className="align-items-center mt-4 bg-beige py-3 rounded-12">
                <Col>
                    <div className="input-group">
                        <input
                            placeholder={`Enter ${targetToken.get("symbol")} amount`}
                            onChange={(e)=> onTargetValueChange(e.target.value)}
                            value={targetValue}
                            // type="number"
                            // type="text"
                            className="form-control"
                            aria-label={targetToken.get("symbol")} aria-describedby="target-input"
                        />
                         <span className="input-group-text fw-bold text-white" id="target-input">
                            <img className="me-2" height={20} src={getTokenIcon(targetToken.get("symbol"))} alt="token"/>
                            <small>{targetToken.get("symbol")}</small>
                        </span>
                    </div>
                </Col>
                {targetValue && targetValue !=="" && <Col className="text-center" xs={1}>
                    <span className="fs-3 text-light-1 ">≈</span>
                </Col>}
                {targetValue && targetValue !=="" &&<Col xs={5}>
                    {/* {renderEstimate()} */}
                    <DexQuotes
                        tokenIn={borrowToken}
                        tokenOut={targetToken}
                        amount={targetValue}
                        op="buy"
                        updateEstimate={(data)=> setEstimate(data)}
                    />
                </Col>}
            </Row>
            {/* {props.estimates && <div className="mt-4">
                {props.estimates.keySeq().map((value, i)=> {
                    // console.log("VAL", value)
                    return <table className="table" key={i}>
                        <tbody>
                        <tr>
                            <td>{value}</td>
                            <td>
                                <table className="table"><tbody>
                                    {props.estimates.get(value).keySeq().map((key, j)=>{
                                        return <tr key={j}>
                                            <td>{key}</td> 
                                            <td>{JSON.stringify(props.estimates.getIn([value, key]))}</td>
                                        </tr>
                                    })}
                                </tbody></table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                })}
            </div>}
             */}
            <div className="mt-2 py-1 mb-4"><small className='text-light-1'>Select borrow limit</small></div>
            {/* <Slider
                disabled={!isValidEstimate}
                // styles={sliderStyles}
                axis="x"
                xmin={1.1}
                xmax={Number(buyFactor)}
                x={selectedBuyFactor}
                onChange={({ x }) => setSelectedBuyFactor(x)}
            /> */}

            {/* {Array(Number(buyFactor)).fill().map((a, i)=> {
                return <Badge
                onClick={()=> setSelectedBuyFactor(i+1)}
                bg={i+1 === selectedBuyFactor ? "primary": "dark"}
                className="me-2 cursor-pointer px-3 py-1" 
                key={i}
                >{i+1}x</Badge>
            })} */}

            <InputRange
                formatLabel={value => `${value.toFixed(1)}x`}
                maxValue={Number(buyFactor)}
                minValue={1.1}
                step={0.1}
                value={selectedBuyFactor}
                onChange={value => setSelectedBuyFactor(value)}
            />
            {renderEstimateBreakup()}
            {/* <div>{fixed2Decimals(maxBorrow, borrowToken.get("decimals"))} {borrowToken.get("symbol")} </div> */}

            {walletBalance < partialAmount && <Alert variant="secondary" className="mt-4 small fw-bold">
                <small>
                    Insufficient wallet balance
                    <span className="ms-3 me-3 text-muted">|</span>
                    <i className="fa fa-wallet"/> {walletBalanceDisplay} {borrowToken.get("symbol")}
                </small>
            </Alert>}
            {borrowAmount > Number(props.totalCash) && <Alert variant="secondary" className="mt-4 small fw-bold">
                <small>
                    Not enough pool liquidity
                    <span className="ms-3 me-3 text-muted">|</span>
                    Available: {fixed2Decimals(props.totalCash, borrowToken.get("decimals"))} {borrowToken.get("symbol")}
                </small>
            </Alert>}

            <div className='d-grid mt-4'>
                <Button
                    disabled={!isValidEstimate || walletBalance < partialAmount} 
                    size="lg"
                    onClick={()=> borrowAndBuy()}
                >
                    Pay
                    {partialAmount > 0 && ` ${fixed2Decimals(partialAmount, borrowToken.get("decimals"))} ${borrowToken.get("symbol")}`}
                </Button>
            </div>

        </div>
    ) 
}

const mapStateToProps = (state, props) => {
    return {
        themeMode: state.get("themeMode"),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        estimates: state.hasIn(["swapEstimates", props.targetToken.get("uAddress")]) ? state.getIn(["swapEstimates", props.targetToken.get("uAddress")]) : fromJS({}),
        totalCash: state.hasIn(["totalUnderlyingCash", props.borrowToken.get("address")]) ? state.getIn(["totalUnderlyingCash",  props.borrowToken.get("address")]) : 0,
        uniswapQuoter: state.getIn(["contracts", "uniswapQuoter"]),
        libAddress: state.getIn(["contracts", "libABI"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchEstimate: (contract, data) => dispatch(fetchEstimate(contract, data)),
        fetchTotalCash: (contract, address) => dispatch(fetchTotalCash(contract, address)),
        borrowAndBuy: (web3, contract, accAddress, data) => dispatch(borrowAndBuy(web3, contract, accAddress, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Borrow);