import { useState } from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button, Spinner, Badge, Popover, OverlayTrigger, ListGroup, ListGroupItem} from 'react-bootstrap';

import {getTokenIcon, fixed2Decimals, toPrecisin, getUSDPrice, mulFixed, divFixed, decimal2Fixed} from '../../../utils';
import BorrowModal from '../../dashboard/components/BorrowModal';
import LendModal from '../../dashboard/components/LendModal';
import { fromJS } from 'immutable';
import SettleModal from '../../dashboard/components/SettleModal';
import PositionModal from './PositionModal';

function BorrowingAssets(props) {
    const [expand, setExpand] = useState(false);
    const [activeToken, setActiveToken] = useState(null);
    const [activeWithdrawToken, setActiveWithdrawToken] = useState(null);
    const [activeSettleToken, setActiveSettleToken] = useState(null);
    const [activePosition, setActivePosition] = useState(null);
    const borrowAssets = props.assets.filter((item)=> item.get("borrowEnabled"));
    let boughtAssets = [];
    props.assets.filter((item)=> item.get("buyEnabled") === true).map((asset)=> {
        // if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
        //     boughtAssets.push(asset);
        // }
        boughtAssets.push(asset);
    })

    const getAPY = (address) => {
        let apy = props.accountSnapshot.getIn([address, "borrowAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const getLendAPY = address => {
        let apy = props.accountSnapshot.getIn([address, "lendAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const getTotalValue = () => {
        let totalValue = 0;
        boughtAssets.forEach((asset)=> {
            if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
                totalValue = totalValue + Number(getUSDPrice(
                    props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                    props.accountSnapshot.getIn([asset.get("address"), "lendBalance"]),
                    asset.get("decimals")
                ))
            }
         });
         return totalValue;
    }

    const getBorrowBalances = () => {
        let totalBorrowBalance = 0;
        let assetsBorrowBalance = fromJS({});
        borrowAssets.forEach((asset) => {
            assetsBorrowBalance = assetsBorrowBalance.set(asset.get("address").toLowerCase(), fromJS({"symbol": asset.get("symbol"), "decimals": asset.get("decimals"), "amount": props.accountSnapshot.getIn([asset.get("address"), "borrowBalance"])}));
            totalBorrowBalance = totalBorrowBalance + Number(getUSDPrice(
                props.accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
                props.accountSnapshot.getIn([asset.get("address"), "borrowBalance"]),
                asset.get("decimals")
            ));
        });
        return {totalBorrowBalance, assetsBorrowBalance}
    }

    // let borrowBalance = props.accountSnapshot.hasIn([borrowAsset.get("address"), "borrowBalance"]) ? props.accountSnapshot.getIn([borrowAsset.get("address"), "borrowBalance"]) : 0;
    const {totalBorrowBalance, assetsBorrowBalance} = getBorrowBalances();
    // let borrowBalanceDisplay = fixed2Decimals(borrowBalance, borrowAsset.get("decimals"), 2);

    const {data, overallCurrent} = props;
    const totalInterest = totalBorrowBalance > 0 ? totalBorrowBalance - (overallCurrent.get("totalBorrow") - overallCurrent.get("totalRepaid")) : 0;
    const totalValue = getTotalValue();
    // const overallProfit = totalValue - Number(getUSDPrice(
    //     props.accountSnapshot.getIn([borrowAsset.get("address"), "oraclePrice"]),
    //     overallCurrent.get("totalBorrow")+ overallCurrent.get("totalDownpayment") - overallCurrent.get("totalRepaid") ,
    //     // + interest,
    //     borrowAsset.get("decimals")
    // ));
    const overallProfit = totalValue - (
        overallCurrent.get("totalBorrow")+ overallCurrent.get("totalDownpayment") - overallCurrent.get("totalRepaid")
        + totalInterest
    );
    // console.log("Postion Data", data.toJS(), overallCurrent.toJS(), interest);
    return (
        <Card body className="mt-4">
             <Row>
                <Col className="g-0">
                    <div className="fs-6">Investment &amp; Loan</div>
                    <div className="small text-muted">Tokens you have borrowed for a collateral</div>
                </Col>
             </Row>
            <div className="token-list">
                {props.isLoading && <div className="text-center mt-2"><Spinner size="sm" variant="secondary" animation="grow"/></div>}
                <Row className="token-list-item " style={{position: "relative"}}>
                            <Col>
                                <div className="title">Current Value</div>
                                <div className="value text-success">$ {totalValue.toFixed(2)}</div>
                            </Col>
                            <Col>
                                <div className="title">Downpayment</div>
                                {/* <div className="value">{fixed2Decimals(overallCurrent.get("totalDownpayment"), borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")}</div> */}
                                <div className="value">$ {overallCurrent.get("totalDownpayment").toFixed(2)}</div>
                            </Col>
                            <Col>
                                <div className="title">Total Outstanding</div>
                                {/* <div className="value">{borrowBalanceDisplay} {borrowAsset.get("symbol")}</div> */}
                                <div className="value"><Item title="Total Outstanding" value={totalBorrowBalance.toFixed(2)} isPrice={true} items={assetsBorrowBalance} /></div>
                            </Col>
                            <Col>
                                <div className="title">Disbursed</div>
                                {/* <div className="value">{fixed2Decimals(overallCurrent.get("totalBorrow"), borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")}</div> */}
                                <div className="value">$ {overallCurrent.get("totalBorrow").toFixed(2)}</div>

                            </Col>
                            <Col>
                                <div className="title">Interest</div>
                                <div className="value">
                                    {/* {fixed2Decimals(interest, borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")} */}
                                    $ {totalInterest.toFixed(2)}
                                </div>
                            </Col>
                            <Col>
                                <div className="title">Profit</div>
                                <div className="value">
                                    <span className={`text-${overallProfit >= 0 ? "success": "danger"}`}>{overallProfit >=0 ? "$": "- $"}&nbsp;{Math.abs(overallProfit).toFixed(2)}</span>
                                </div>
                            </Col>
                           
                            <Col md={1} className="text-end">
                                <div className='d-grid'>
                                    <Button variant="primary" onClick={()=> setActiveToken(fromJS({
                                        targetToken: props.assets.find((item)=> item.get("buyEnabled")),
                                        borrowAssets: props.assets.filter((item)=> item.get("borrowEnabled"))
                                    }))}>Repay</Button>
                                </div>
                            </Col>
                        {/* <Button
                        onClick={()=> setExpand(!expand)}
                        size='sm'
                        variant={props.theme}
                        style={{
                            position: "absolute",
                            bottom: "-10px",
                            right: "50%",
                            zIndex: 99
                        }}
                    >
                        <i className={`fas fa-angle-${expand ? "up": "down"}`}/>
                    </Button> */}
                   
                </Row>
                <div className='fs-6 mt-4'>Assets</div>
                <hr/>
                {boughtAssets.map((asset, j) => <Asset 
                    key={j}
                    asset={asset}
                    currentData={data.getIn([asset.get("address").toLowerCase(), "current"])}
                    closedData={data.getIn([asset.get("address").toLowerCase(), "closed"])}
                    accountSnapshot={props.accountSnapshot}
                    totalInterest = {totalInterest}
                    assetsBorrowBalance={assetsBorrowBalance}
                    theme={props.theme}
                    setActiveWithdrawToken= {()=> setActiveWithdrawToken(asset)}
                    setActiveSettleToken={()=> setActiveSettleToken(asset)}
                    setActivePosition = {()=> setActivePosition(data.get(asset.get("address").toLowerCase()))}
                />)}
            </div>
            {activeToken !==null && <BorrowModal
                repayOnly={true}
                activeTab="Repay"
                show={activeToken !== null}
                tokens={activeToken}
                getAPY={(address) => getAPY(address)}
                onHide={()=> setActiveToken(null)}
            />}

            {activeWithdrawToken !==null && <LendModal
                activeTab="Withdraw"
                show={activeWithdrawToken !== null}
                token={activeWithdrawToken}
                lendAPY={getLendAPY(activeWithdrawToken.get("address"))}
                onHide={()=> setActiveWithdrawToken(null)}
            />}


            {activeSettleToken !==null && <SettleModal
                show={activeSettleToken !== null}
                token={activeSettleToken}
                // borrowAssets={borrowAssets.filter((item)=> item.get("address") !== activeSettleToken.get("address"))}
                borrowAssets={props.assets.filter((item) => activeSettleToken.get("borrowAssets").includes(item.get("address")))}
                onHide={()=> setActiveSettleToken(null)}
            />}

            {activePosition && <PositionModal
                show={activePosition !== null}
                data={activePosition}
                borrowAsset={borrowAssets.get(0)}
                onHide={()=> setActivePosition(null)}
            />}

        </Card>
    )
}


function Asset({
    asset, currentData, closedData, accountSnapshot, assetsBorrowBalance, totalInterest, theme,
    setActiveWithdrawToken, setActiveSettleToken, setActivePosition
}) {
    const [expand, setExpand] = useState(false);
    
    if(currentData.get("balanceTokens") <=0) {
        return null;
    }
    const assetInterest = Number(mulFixed(totalInterest, currentData.get("borrowPer")));
    const outStanding = currentData.get("totalBorrow") + assetInterest + - currentData.get("totalRepaid");

    const exchangeRate = accountSnapshot.getIn([asset.get("address"), "exchangeRateStored"]);
    const balanceCurrent = divFixed(mulFixed(currentData.get("balanceTokens"), exchangeRate), decimal2Fixed(1, 18));

    const totalPrice = getUSDPrice(
        accountSnapshot.getIn([asset.get("address"), "oraclePrice"]),
        // accountSnapshot.getIn([asset.get("address"), "lendBalance"]),
        balanceCurrent,
        asset.get('decimals'));
    // const borrowedPrice = getUSDPrice(
    //     accountSnapshot.getIn([borrowAsset.get("address"), "oraclePrice"]),
    //     currentData.get("borrow") + currentData.get("downpayment") - currentData.get("repaid") + assetInterest,
    //     borrowAsset.get('decimals')
    // )
    const borrowedPrice =  currentData.get("totalBorrow") + currentData.get("totalDownpayment") - currentData.get("totalRepaid") + assetInterest;
    const profit = Number(totalPrice) - Number(borrowedPrice);
    
    return <Row className="token-list-item borrowing" style={{position: "relative"}}>
        <Col>
            <Row>
                <Col>
                    <div className="title">{asset.get("symbol")}</div>
                    <div className='value'>
                        <img height="12" src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                       {fixed2Decimals(balanceCurrent, asset.get("decimals"), 2)}
                    </div>
                    
                    
                    {/* {fixed2Decimals(currentData.get("balance"), asset.get("decimals"))} */}
                    
                </Col>
                <Col>
                    <div className="text-success">
                        <div className="title">Current Value</div>
                        <div className="value text-success">
                            $ {totalPrice}
                        </div>
                    </div>
                </Col>
                <Col>  
                    <div className="title">Downpayment</div>
                    <div className="value"> 
                        {/* $ {currentData.get("totalDownpayment").toFixed(2)} */}
                        {/* <Item title="Downpayment" items={currentData.get("downpayment")}/> */}
                        <Item
                            title="Downpayment"
                            value={currentData.get("totalDownpayment").toFixed(2)}
                            isPrice items={currentData.get("downpayment")}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="title">Avg Buying Price</div>
                    <div className="value">
                        {/* {fixed2Decimals(currentData.get("avgBuyingPrice"), borrowAsset.get("decimals"), 2)} {borrowAsset.get("symbol")} */}
                        {/* {currentData.get("avgBuyingPrice")} */}
                        <Item items={currentData.get("avgBuyingPrices")} />
                    </div>
                </Col>
                <Col>
                    <div className="title">Profit</div>
                    <div className="value">
                        <span className={`text-${profit >= 0 ? "success": "danger"}`}>{profit >=0 ? "$": "- $"}&nbsp;{Math.abs(profit).toFixed(2)}</span>
                    </div>
                </Col>
                {/* <Col>
                    <div className="title">Withdraw</div>
                    <div className="value">
                        <img src={getTokenIcon(asset.get("symbol"))} alt="token"/>
                        {fixed2Decimals(currentData.get("redeemed"), asset.get("decimals"), 2)}
                    </div>
                </Col> */}
                
                {/* <Col>
                    <div className='d-grid mb-2'>
                        <Button variant="primary" onClick={()=> setActiveWithdrawToken()}>Withdraw</Button>
                    </div>
                    <div className='d-grid'>
                        <Button variant="primary" onClick={()=> setActiveSettleToken()}>Sell</Button>
                    </div>
                </Col>      */}
                <Col md={1}>
                    <div className='d-grid'>
                        <Button variant="primary" onClick={()=> setActiveWithdrawToken()}>Withdraw</Button>
                    </div>
                </Col>
                <Col md={1}>
                    <div className='d-grid'>
                        <Button variant="primary" onClick={()=> setActiveSettleToken()}>Sell</Button>
                    </div>
                </Col>
                
            </Row>
            {/* {expand && <div>
                <hr/>
                <Row>
                    
                    <Col>  
                        <div className="title">Downpayment</div>
                        <div className="value"> 
                            <Item
                                title="Downpayment"
                                value={currentData.get("totalDownpayment").toFixed(2)}
                                isPrice items={currentData.get("downpayment")}
                            />
                        </div>
                    </Col>
                    <Col>  
                        <div className="title">Borrowed</div>
                        <div className="value">
                            <Item
                                title="Disbursed"
                                value={currentData.get("totalBorrow").toFixed(2)}
                                isPrice items={currentData.get("borrow")}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className="text-success">
                            <div className="title">Interest</div>
                            <div className="value">
                                {assetInterest > 0 ? <span>
                                    $ {assetInterest.toFixed(2)}
                                </span> : "---"}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="text-success">
                            <div className="title">Repaid</div>
                            <div className="value">
                            <Item
                                    title="Repaid"
                                    value={currentData.get("totalRepaid").toFixed(2)}
                                    isPrice items={currentData.get("repaid")}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="text-success">
                            <div className="title">Outstanding</div>
                            <div className="value">
                                {outStanding < 0 ? "0" : `$ ${outStanding.toFixed(2)}`}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>} */}
            
            {closedData.size > 0 && <Badge
                bg={theme}
                style={{
                    position: "absolute",
                    bottom: "-10px",
                    left: "0px",
                    zIndex: 99
                }}
                onClick={()=> setActivePosition()}
                className='cursor-pointer small'
             >
                <span className='text-light-1'><i className='fas fa-eye me-2'></i>{closedData.size } Closed Positions</span>
            </Badge>}
            {/* <Button
                onClick={()=> setExpand(!expand)}
                size='sm'
                variant={theme}
                style={{
                    position: "absolute",
                    bottom: "-10px",
                    right: "50%",
                    zIndex: 99
                }}
            >
                <i className={`fas fa-angle-${expand ? "up": "down"}`}/>
            </Button> */}
            {/* <div style={{
               position: "absolute",
               bottom: "-10px",
               right: "0px",
               zIndex: 99 
            }}>
                <Button className="me-2" size="sm" variant="primary" onClick={()=> setActiveWithdrawToken()}>Withdraw</Button>
                <Button size="sm" variant="primary" onClick={()=> setActiveSettleToken()}>Sell</Button>
            </div> */}
            {/* <div  style={{
               position: "absolute",
               bottom: "-15px",
               right: "0px",
               width: "100%"
            }}>
                <Row>
                <Col className="text-end">
                    <Button
                        onClick={()=> setExpand(!expand)}
                        size='sm'
                        variant={theme}
                        // style={{
                        //     position: "absolute",
                        //     bottom: "-10px",
                        //     right: "50%",
                        //     zIndex: 99
                        // }}
                    >
                        <i className={`fas fa-angle-${expand ? "up": "down"}`}/>
                    </Button>
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <div className='d-grid'>
                                <Button size="sm" variant="primary" onClick={()=> setActiveWithdrawToken()}>Withdraw</Button>
                            </div>
                        </Col>
                        <Col>
                            <div className='d-grid'>
                                <Button size="sm" variant="primary" onClick={()=> setActiveSettleToken()}>Sell</Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
                </Row>
            </div> */}
        </Col>
    </Row>
}

export function Item({value, items, isPrice=false, title}) {
    const keys = items.keySeq().toArray();
    const filtered = keys.filter((key, i) => items.getIn([key, "amount"]) > 0);
    const getPopover = () => {
        return (
            <Popover id={Math.random()}>
                {/* {title && <Popover.Header as="h3">{title}</Popover.Header>} */}
                <Popover.Body>
                    <ListGroup variant='flush'>
                        {filtered.map((key, i)=> <ListGroupItem key={i}>
                            <img className='me-2' src={getTokenIcon(items.getIn([key, "symbol"]))} height="16" alt="token"/> {fixed2Decimals(items.getIn([key, "amount"]), items.getIn([key,"decimals"]))} {items.getIn([key, "symbol"])}
                        </ListGroupItem>)}
                    </ListGroup>
                </Popover.Body>
            </Popover>
        )
    }
    if (value === undefined) {
        if (filtered.length > 0) {
            let item = items.get(filtered[0]);
            value = `${fixed2Decimals(item.get("amount"), item.get("decimals"), 2)} ${item.get("symbol")}`;
            value = <span>
                <img src={getTokenIcon(item.get("symbol"))} height="14"  alt="token"/>
                {fixed2Decimals(item.get("amount"), item.get("decimals"), 2)}</span>
        }
    }
    if (!value) {
        return "--";
    }
    return (
        <span>
             {isPrice && "$"}{value}
            { (isPrice ? filtered.length > 0 : filtered.length > 1) && <OverlayTrigger trigger="click" placement="bottom" overlay={getPopover()}>
                {isPrice ? <span className='ms-1 cursor-pointer'>
                    <i style={{ fontSize: "12px"}} className='fas fa-circle-chevron-down'/>
                </span> :<span className='ms-1 cursor-pointer' style={{
                    display: "inline-block",
                    lineHeight: "11px",
                    fontSize: "10px",
                    // border: "1px solid #8C7DF7",
                    // color: "#8C7DF7 !important",
                    // backgroundColor: "#212529",
                    borderRadius: "5px",
                    padding: "1px 2px"
                }}>
                    +{filtered.length - 1}
                    <i className='fas fa-circle-chevron-down ms-1'/>
                </span>}
            </OverlayTrigger>}
        </span>
    )
}

const mapStateToProps = state => {
    return {
        theme: state.get("themeMode"),
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
        transactions: state.getIn(["transactions", "data"]),
        isLoading: state.getIn(["transactions", "isLoading"])
    }
}

export default connect(mapStateToProps)(BorrowingAssets);