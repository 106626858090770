import { Alert, Button} from 'react-bootstrap';
import {connect} from 'react-redux';
import { getContract } from '../../../../utils';
import { fetchAccountSnapshot, xdcFaucet } from '../../../../redux/actions';
import { xdcFaucetAbi } from '../../../../utils/abi';


function BalanceHelp (props) {

    let underlyingBalance = 0;
    if ( props.uTokenMeta.hasIn([props.token.get("uAddress"), "balance"]) ) {
        underlyingBalance =  Number(props.uTokenMeta.getIn([props.token.get("uAddress"), "balance"]));
    }
    if (props.nativeBalance > 0 && underlyingBalance > 0) {
        return null;
    }

    const sendRequest = () => {

        const contract = getContract(props.web3, xdcFaucetAbi, props.xdcFaucetAddress);
        props.xdcFaucet(props.web3, contract, props.accAddress)

    }

    return <div className="h6 small fw-light">
        {props.nativeBalance === 0 && <Alert variant="primary" className={`${props.position === "header" ? "float-start me-2": "mb-2"}`}>
            <div className="">
                {/* Your MATIC balance is {props.nativeBalance/1e18}. */}
                XDC is required to pay gas fee for a transaction.<br/>
                <a  rel="noreferrer" className="btn btn-sm btn-primary mt-2" target="_blank" href="https://faucet.apothem.network/">
                    <i className="fa fa-up-right-from-square me-2"></i>
                    Request XDC
                </a>
            </div>
        </Alert>}
        {underlyingBalance === 0 && <Alert variant="primary" className={`${props.position === "header" ? "float-end ": ""}`}>
            <div>
                {/* Your {props.token.get("symbol")} balance is {underlyingBalance/1e6}. */}
                To Supply or Borrow, you need {props.token.get("symbol")} in your wallet.<br/>
                <Button onClick={()=> sendRequest()} size="sm" variant='primary' className="mt-2">
                    Request {props.token.get("symbol")}
                </Button>
            </div>
        </Alert>}

     </div>
        
       
}

const mapStateToProps = state => {
    return {
        accAddress: state.get("accAddress"),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        nativeBalance: state.get("nativeBalance"),
        activeChain: state.get("supportedChains").find((chain)=> chain.get("chainId") === state.get("chainId")),
        web3: state.get("web3"),
        xdcFaucetAddress: state.getIn(["contracts", "xdcFaucetAddress"]),
        assets: state.get("assets"),
    }
}

const mapDispatchToProps = dispatch => {
    return  {
        fetchAccountSnapshot: (contract, accAddress, assets) => dispatch(fetchAccountSnapshot(contract, accAddress, assets)),
        xdcFaucet: (web3, contract, accAddress) => dispatch(xdcFaucet(web3, contract, accAddress))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceHelp)