import {useEffect, useState} from 'react';
import {Modal, Row, Col, Spinner, ListGroup, Button, DropdownButton, Dropdown} from 'react-bootstrap';
import { decimal2Fixed, decodeDexData, divFixed, fixed2Decimals, getContract, getTokenIcon } from '../../../utils';
import {connect} from 'react-redux';
import { comptrollerABI, libABI, pTokenABI } from '../../../utils/abi';
import { fetchEstimate, swapAndSettle } from '../../../redux/actions';
import { fromJS } from 'immutable';
import DexQuotes from './DexQuotes';

function SettleModal(props) {
    const [sliderBalance, setSliderBalance] = useState(0);
    const [borrowAsset, activeBorrowAsset] = useState(props.borrowAssets.get(0));
    const [estimate, setEstimate] = useState(null);

    // useEffect(()=> {
    //     if(Number(sliderBalance) > 0  && Number(sliderBalance) <= underlyingBalance) {
    //         getEstimate(sliderBalance);
    //     }
    // }, [sliderBalance]);
    

    const token = props.token;
    // const borrowAsset = props.borrowAsset;
    let underlyingBalance= 0;
    
    if ( props.snapshot.hasIn([token.get("address"), "lendBalance"]) ) {
        underlyingBalance = fixed2Decimals(props.snapshot.getIn([token.get("address"), "lendBalance"]), token.get("decimals"));
    }
    
    // const getEstimate = value => {
    //     let data = {
    //         borrowToken: token.get("uAddress"),
    //         targetToken: borrowAsset.get("uAddress"),
    //         value: value, 
    //         decimalValue: decimal2Fixed(value, token.get("decimals")),
    //         uniswapQuoter: props.uniswapQuoter,
    //         in: true
    //     }
    //     const contract = getContract(props.web3, libABI, props.libAddress);
    //     props.fetchEstimate(contract, data);
    // }

    const getNumTokens = ()=> {
        const balance = decimal2Fixed(sliderBalance, token.get("decimals"));
        const tokens = divFixed(balance, fixed2Decimals(props.snapshot.getIn([token.get("address"), "exchangeRateStored"]), 18));
        return tokens;
    }

    const swapAndSettle = (swapEstimate)=> {
        const contract = getContract(props.web3, pTokenABI, token.get("address"));
        props.swapAndSettle(props.web3, contract, props.accAddress, {
            numTokens: getNumTokens(),
			borrowedAsset: borrowAsset.get("address"),
			borrowedAssetAmount: swapEstimate.quote,
            dexData: decodeDexData(props.web3, swapEstimate.dexId,  decimal2Fixed(3, 3))
        });
        props.onHide();
    }

    const isValidEstimate = estimate !== null && Number(estimate.quote) > 0;
    const isValidSliderBalance = sliderBalance !=="" && Number(sliderBalance) > 0 &&  Number(sliderBalance) <= Number(underlyingBalance);

    const renderEstimate = () => {
        if( isValidSliderBalance && isValidEstimate ) {
                return <div className="mt-4">
                    {/* <div className='mb-4'>
                        {sliderBalance} {token.get("symbol")}
                        <span className="ms-2 me-2">≈</span>
                        {fixed2Decimals(swapEstimate, borrowAsset.get("decimals"))}
                        &nbsp;{borrowAsset.get("symbol")}
                    </div> */}
                    <SettleEstimate
                        themeMode={props.themeMode}
                        swapEstimate={estimate.quote}
                        token={token}
                        borrowAsset={borrowAsset}
                        contract= {getContract(props.web3, comptrollerABI, props.comptroller)}
                        accAddress={props.accAddress}
                        swapAndSettle = {()=> swapAndSettle(estimate)}
                        getNumTokens={()=> getNumTokens()}
                        reset = {()=> setSliderBalance(0)}
                    />
                </div>;
        } else {
            return null;
        }
    }

    // const renderEstimate = () => {
    //     if(estimates.has(sliderBalance)) {
    //         if(estimates.getIn([sliderBalance, "isLoading"])) {
    //             return <div className='text-center mt-4'> <Spinner animation="grow" variant="secondary" /></div>
    //         } else if (estimates.getIn([sliderBalance, "error"]) !== null) {
    //             return <small className="text-light-2 mt-4">Not enough Uniswap Liquidity</small>;
    //         } else {
    //             const swapEstimate = estimates.getIn([sliderBalance, "estimate"])
    //             return <div className="mt-4">
    //                 {/* <div className='mb-4'>
    //                     {sliderBalance} {token.get("symbol")}
    //                     <span className="ms-2 me-2">≈</span>
    //                     {fixed2Decimals(swapEstimate, borrowAsset.get("decimals"))}
    //                     &nbsp;{borrowAsset.get("symbol")}
    //                 </div> */}
    //                 <SettleEstimate
    //                     themeMode={props.themeMode}
    //                     swapEstimate={swapEstimate}
    //                     token={token}
    //                     borrowAsset={borrowAsset}
    //                     contract= {getContract(props.web3, comptrollerABI, props.comptroller)}
    //                     accAddress={props.accAddress}
    //                     swapAndSettle = {()=> swapAndSettle(swapEstimate)}
    //                     getNumTokens={()=> getNumTokens()}
    //                     reset = {()=> setSliderBalance(0)}
    //                 />
    //             </div>;
    //         }
    //     } else {
    //         return null;
    //     }
    // }

    console.log("Estimate", estimate);

    return(
        <Modal
            show={props.show}
            onHide={()=> props.onHide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            contentClassName={props.themeMode === "dark" ? "bg-black-modal": ""}
        >
            <Modal.Header closeButton className="position-relative text-center border-0">
            <Modal.Title id="contained-modal-title-vcenter">
                    <div className='modal-header-token'>
                        <div className="icon">
                            <img src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                        </div>
                        <div className='text-light-2 mt-2 fs-5'>
                            {token.get("symbol")}
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='p-4'>
                <Row className="align-items-center">
                    <Col className="border-end border-2">
                        <div><small className='text-light-1'>Sell on DEX {token.get("symbol")}</small></div>
                        <div className="input-group mt-1 ">
                            <input
                                value={sliderBalance}
                                className="form-control"
                                onChange={(e)=> setSliderBalance(Number(e.target.value))}
                                type="number"
                            />
                            <button onClick={()=> setSliderBalance(underlyingBalance)} className="btn btn-primary">Max</button>
                        </div>
                        {/* <div><small className='text-light-1'> <i className="fa fa-wallet me-2" />{token.get("symbol")} Balance</small></div> */}
                    </Col>
                    <Col>
                        <div className="fw-bold mt-1">
                            <img height="20" className='me-2' src={getTokenIcon(token.get("symbol"))} alt={token.get("symbol")}/>
                            {underlyingBalance} 
                        </div>   
                    </Col>
                    
                </Row>
                <hr/>
                <Row>
                    <Col>
                        <div><small className='text-light-1'>Select borrowed asset</small></div>
                        <DropdownButton
                            menuVariant={props.themeMode}
                            variant="primary"
                            title={borrowAsset.has("symbol") ? <span>
                                {borrowAsset.get("symbol")} <img height="20" src={getTokenIcon(borrowAsset.get("symbol"))} alt="token"/>
                            </span> : ""}
                        >
                            {props.borrowAssets.map((token, i)=> {
                                return (
                                    <Dropdown.Item onClick={()=> {
                                        setSliderBalance(0);
                                        // props.onBorrowTokenChange(token);
                                        // fetchCash(token)
                                        activeBorrowAsset(token);
                                    }} key={i} active={borrowAsset.get("address") === token.get("address")}>
                                        {token.get("symbol")}<img src= {getTokenIcon(token.get("symbol"))} alt="token" height="20" className='ms-2'/>
                                    </Dropdown.Item>
                                )
                            })}
                        </DropdownButton> 
                    </Col>
                    <Col>
                        {isValidSliderBalance  && <div>
                            {/* {renderEstimate()} */}
                            <DexQuotes
                                tokenIn={token}
                                tokenOut={borrowAsset}
                                amount={sliderBalance}
                                op="sell"
                                updateEstimate={(data)=> setEstimate(data)}
                            />
                        </div>}
                    </Col>
                </Row>
                <div>
                    {renderEstimate()}
                </div>
                </div>
            </Modal.Body>
        </Modal>
    ) 
}


function SettleEstimate({
    themeMode,
    swapEstimate,
    token,
    borrowAsset,
    contract,
    accAddress,
    swapAndSettle,
    getNumTokens,
    reset
}) {
    const [settleEstimate, setSettleEstimate] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(()=> {
        if (swapEstimate) {
            setSettleEstimate(null);
            getSettleEstimate();
        }
    }, [swapEstimate])

    const getSettleEstimate = async () => {
        setLoading(true);
        await contract.methods.swapAndSettleCalculateRepayAmount(
            accAddress,
            token.get("address"),
            borrowAsset.get("address"),
            getNumTokens(),
            swapEstimate
        ).call((err, result)=> {
            console.log("settle estimate", err, result)
            setSettleEstimate(result);
            setLoading(false);
        });
    }

    if (isLoading) {
        return <div className="text-center">
            <Spinner animation="grow" variant="secondary" size="sm" />
        </div>
    } else if ( settleEstimate == null) {
        return null;
    } 
    else if ( settleEstimate.err === "0" ) {
        const bgClass = themeMode === "dark" ? "bg-dark text-light-2" : "bg-beige text-light-2";
        return <div className='mt-4'>
            <ListGroup>
                <ListGroup.Item
                    className={`d-flex justify-content-between align-items-center ${bgClass}`}
                >
                    <i className="fas fa-circle text-secondary me-2"/>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Swap</div>
                        {fixed2Decimals(settleEstimate.assetTokensAmount, token.get("decimals"))} {token.get("symbol")}
                        <span className="ms-2 me-2">≈</span>
                        {fixed2Decimals(swapEstimate, borrowAsset.get("decimals"))} {borrowAsset.get("symbol")} 
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    className={`d-flex justify-content-between align-items-center ${bgClass}`}
                >
                    <i className="fas fa-circle text-secondary me-2"/>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Repay Borrow Balance</div>
                        {fixed2Decimals(settleEstimate.repayAmountRequired, borrowAsset.get("decimals"))} {borrowAsset.get("symbol")}
                        {/* <div className="small">
                            Your current borrow balance: {fixed2Decimals(settleEstimate.borrowBalance, borrowAsset.get("decimals"))} {borrowAsset.get("symbol")}
                            {Number(settleEstimate.repayAmountRequired) === 0 && <div>
                                You will be able to settle without repaying borrow balance but <b>it will make your portfolio value close to the liquiation point</b>
                            </div>}
                        </div> */}
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    className={`d-flex justify-content-between align-items-center ${bgClass}`}
                >
                    <i className="fas fa-circle text-secondary me-2"/>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Receive {borrowAsset.get("symbol")}</div>
                        {fixed2Decimals(settleEstimate.totalReceivable, borrowAsset.get("decimals"))} {borrowAsset.get("symbol")}
                    </div>
                </ListGroup.Item>
            </ListGroup>
            {/* {Number(settleEstimate.borrowBalance) > 0 && Number(settleEstimate.repayAmountRequired) ===0 && <div className="mt-4"> */}
            {Number(settleEstimate.estimatedLiquidity) > 0 && <div className="mt-4">
                <i className="fas fa-warning me-2"></i> This transaction will make your portfolio value close to the liquidation point.
            </div>}
            <div className="row mt-4">
                <Col className="d-grid">
                    <Button size='lg' onClick={()=> swapAndSettle()}>Proceed</Button>
               </Col>
               <Col className="d-grid">
                    <Button variant='secondary' size="lg" onClick={()=> {
                        reset();
                        setSettleEstimate(null);
                    }}>Cancel</Button>
                </Col>
            </div>
        </div>
    } else {
        return <div className='mt-4'>Could not retreive Sell on Dex estimates. Please try again later.</div>
    }
}

const mapStateToProps = (state, props) => {
   return {
        assets: state.get("assets"),
        themeMode: state.get("themeMode"),
        snapshot: state.get("accountSnapshot"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        comptroller: state.getIn(["contracts", "comptroller"]),
        uniswapQuoter: state.getIn(["contracts", "uniswapQuoter"]),
        libAddress: state.getIn(["contracts", "libABI"]),
        estimates: state.get("swapEstimates")
   }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchEstimate: (contract, data) => dispatch(fetchEstimate(contract, data)),
        swapAndSettle: (web3, contract, accAddress, data) => dispatch(swapAndSettle(web3, contract, accAddress, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettleModal);