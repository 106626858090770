import {useState} from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button} from 'react-bootstrap';

import {getTokenIcon, fixed2Decimals, toPrecisin} from '../../../utils';
import LendModal from '../../dashboard/components/LendModal';

function LendingAssets(props) {
    const [activeToken, setActiveToken] = useState(null);
    let lendAssets = [];
    props.assets.filter((item)=> item.get("lendEnabled") === true).map((asset)=> {
        if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
            lendAssets.push(asset);
        }
    })

    const getAPY = (address) => {
        let apy = props.accountSnapshot.getIn([address, "lendAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }
    return (
        <Card body className="mt-5">
            <Row>
                <Col className="g-0">
                    <div className="fs-6">Supply Assets</div>
                    <div className="small text-muted">Tokens supplied to the protocol</div>
                </Col>
            </Row>
            <div className="token-list">
                {lendAssets.map((token, j)=> {
                    return (
                        <Row key={j} className="token-list-item align-items-center">
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="title">Asset</div>
                                        <div className="value">
                                            <img src={getTokenIcon(token.get("symbol"))} alt="token"/>
                                            {token.get("symbol")}
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="title">APY</div>
                                        <div className="value">
                                            {getAPY(token.get("address"))}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="text-dark fs-5">
                                        {toPrecisin(fixed2Decimals(props.accountSnapshot.getIn([token.get("address"), "lendBalance"]), token.get("decimals")), 2)}
                                        &nbsp;{token.get("symbol")}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3} className='d-grid align-self-end'>
                                <Button onClick={()=> setActiveToken(token)} variant="primary align-bottom">Withdraw</Button>
                            </Col>
                        </Row>
                    )
                })}

                {lendAssets.length === 0 && <Row className="token-list-item fs-6 text-light-1">
                    <Col>
                        You haven't supplied any asset.
                    </Col>
                </Row>}
            </div>
            {activeToken !==null && <LendModal
                activeTab="Withdraw"
                show={activeToken !== null}
                token={activeToken}
                lendAPY={getAPY(activeToken.get("address"))}
                onHide={()=> setActiveToken(null)}
            />}
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot")
    }
}

export default connect(mapStateToProps)(LendingAssets);